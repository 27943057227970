import React, {useEffect, useState} from "react";
import Header from "../components/UI/Header";
import {
  fetchClientData,
  fetchClients,
  fetchCreateNewUserManager,
  fetchDealDocumentsByContractId,
  fetchDealRegistryPack,
  fetchDeals,
  fetchDirectReceipt,
  fetchLastAllRepaymentDate,
  fetchPayment,
  fetchPayOffDebts,
  fetchPointsOfSale,
  fetchReviveApplication,
  fetchRevokeAccount,
  fetchSetDealIssued,
  fetchTerritorialDirections,
  fetchUpdateClientData,
  fetchUploadAverageIncome,
  fetchUploadFullCost,
  fetchUploadPointOfSale,
  updateApplicationPointOfSaleAdm,
  fetchUploadManualPayments,
  fetchCurrentMpl80State,
  changeCurrentMpl80State,
  fetchCurrentMpl5080State,
  changeCurrentMpl5080State,
  changeCurrentPaymentState,
  fetchCurrentPaymentState,
  fetchUploadPskMapping,
  fetchRejectCrifApplication,
  sendDocumentsToIbso,
  getAdminDocumentTypes,
  generateDocumentRequest,
  fetchCurrentRateFilterState,
  changeCurrentRateFilterState
} from "../api";
import {Select, TextInput} from "../components/UI/FormItems";
import {Field, Form, Formik} from "formik";
import {ReactComponent as Upload} from "../asssets/icons/upload.svg";
import {ReactComponent as Print} from "../asssets/icons/print.svg";
import Switch from '@material-ui/core/Switch';

export default function AdminOffice() {

  const [page, setPage] = useState("issue");
  const [clients, setClients] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [docType, setDocType] = useState("");
  const [clientId, setClientId] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [deals, setDeals] = useState([]);
  const [creditContractId, setCreditContractId] = useState(false);
  const [client, setClient] = useState(false);
  const [files, setFiles] = useState([]);
  const [averageIncomeAttached, setAverageIncomeAttached] = useState(false);
  const [fullCostAttached, setFullCostAttached] = useState(false);
  const [pointOfSaleAttached, setPointOfSaleAttached] = useState(false);
  const [dealTypeMappingAttached, setDealTypeMappingAttached] = useState(false);
  const [paymentsAttached, setPaymentsAttached] = useState(false);
  const [loading, setLoading] = useState(false);
  const [territorialDirections, setTerritorialDirections] = useState([]);
  const [pointsOfSale, setPointsOfSale] = useState([]);
  const [showSelectPOS, setShowSelectPOS] = useState(false);
  const [lastAllRepaymentDate, setLastAllRepaymentDate] = useState("");
  const [currentMpl80State, setCurrentMpl80State] = useState(false);
  const [currentMpl5080State, setCurrentMpl5080State] = useState(false);
  const [currentPaymentState, setCurrentPaymentState] = useState(false);
  const [interestFilterList, setInterestFilterList] = useState([]);
  const [requestsLocked, setRequestsLocked] = useState(false);

  useEffect(() => {
    async function initialize() {
      await fetchTerritorialDirections().then((data) => setTerritorialDirections(data));
      await fetchLastAllRepaymentDate().then((data) => setLastAllRepaymentDate(() => data));
      await fetchCurrentMpl80State().then((data) => setCurrentMpl80State(() => data));
      await fetchCurrentMpl5080State().then((data) => setCurrentMpl5080State(() => data));
      await fetchCurrentPaymentState().then((data) => setCurrentPaymentState(() => data));
      await fetchCurrentRateFilterState().then((data) => setInterestFilterList(() => data));
      await getAdminDocumentTypes().then((data) => setDocTypes(data));
    }
    initialize().then(() => {});
  }, []);

  const handleClick = (value) => {
    setPage(value);
  };

  const handleSelectClient = async (value) => {
    fetchDeals(value).then((data) => setDeals(data));
    const result = await fetchClientData(value);
    setClient(result.data);
    setClientId(result.data.id);
    setSelectedClient(result.data.lastName + " " + result.data.firstName + " " + result.data.middleName);
  };

  const handleSelectTerritorialDirection = async (value) => {
    const response = await fetchPointsOfSale(value);
    setPointsOfSale(response);
    setShowSelectPOS(true);
  };

  const handleSelectPointOfSale = async (value) => {
    const result = await updateApplicationPointOfSaleAdm(creditContractId, value);
    if (result) {
      alert("Успешно установили точку продаж для заявки");
    } else {
      alert("Не удалось установить точку продаж для заявки");
    }
  };

  const handleDealDocumentsGenerate  = async () => {
    if (creditContractId) {
      await fetchDealDocumentsByContractId(creditContractId);
    } else {
      alert("Сделка не выбрана")
    }
  }

  const handleDealRegistryPackGenerate  = async () => {
    if (creditContractId) {
      await fetchDealRegistryPack(creditContractId);
    } else {
      alert("Сделка не выбрана")
    }
  }

  const uploadFile = async (e) => {
    const temp = files.filter((i) => i?.id !== e.target.id);

    setFiles([
      ...temp,
      { id: e.target.id, url: URL.createObjectURL(e.target.files[0]), name: e.target.files[0].name  },
    ]);

    if (e.target.id === "averageIncome") {
      setAverageIncomeAttached(true);
    } else if (e.target.id === "fullCost") {
      setFullCostAttached(true);
    } else if (e.target.id === "pointOfSale") {
      setPointOfSaleAttached(true);
    } else if (e.target.id === "payments") {
      setPaymentsAttached(true);
    } else if (e.target.id === "dealTypeMapping") {
      setDealTypeMappingAttached(true);
    }
  };



  let LiStyle = {
    borderRadius: "8px",
    border: "2px solid black",
    padding: "15px",
    margin: "5px",
    cursor: "pointer",
    fontSize: "16px",
  };

  return (
    <div>
      <Header title="Кабинет администратора" />
      <section className="admin-office" style={{ display: "flex" }}>
        <table>
          <tr>
            <td style={{ width: "160px" }}>
              <nav className="admin-office__tabs" style={{ marginLeft: "-40px" }}>
                <ul className="admin-office__list">
                  <li
                      onClick={() => handleClick("issue")}
                      className={page === "issue" && "bold-a"}
                      style={LiStyle}
                  >
                    Выдача/Закрытие
                  </li>
                  <li
                      onClick={() => handleClick("repayments")}
                      className={page === "repayments" && "bold-a"}
                      style={LiStyle}
                  >
                    Погашения
                  </li>
                  <li
                      onClick={() => handleClick("editingClientData")}
                      className={page === "editingClientData" && "bold-a"}
                      style={LiStyle}
                  >
                    Редактирование <br/> данных клиента
                  </li>
                  <li
                      onClick={() => handleClick("references")}
                      className={page === "references" && "bold-a"}
                      style={LiStyle}
                  >
                    Загрузка справочников
                  </li>
                  <li
                      onClick={() => handleClick("addUser")}
                      className={page === "addUser" && "bold-a"}
                      style={LiStyle}
                  >
                    Добавление пользователей
                  </li>
                  <li
                      onClick={() => handleClick("other")}
                      className={page === "other" && "bold-a"}
                      style={LiStyle}
                  >
                    Прочее
                  </li>
                </ul>
              </nav>
            </td>
            <td style={{ width: "800px" }}>
              <div className="admin-office__content">
                {page === "issue" && <div>
                  <fieldset>
                    <Formik
                        initialValues={{
                          applicationId: null
                        }}
                        onSubmit={async (values) => {
                          await fetchReviveApplication(values.applicationId);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Реанимировать заявку по id
                          </div>
                          <div style={{ marginTop: "20px"}}/>

                          <TextInput
                              label="id Заявки : "
                              name="applicationId"
                              placeholder="123456"
                              style={{ width: "100px" }}
                          />

                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Реанимировать заявку
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                  <fieldset>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {}}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                            Смена точки продаж для заявки по сделке
                          </div>
                          {creditContractId && <Select {...territorialDirections}
                                                       label="Территориальная дирекция:"
                                                       name="territorialDirection"
                                                       Территориальная дирекция
                                                       onChange={(e) => handleSelectTerritorialDirection(e.target.value)}
                          >
                            <option value={0}>Не выбрана</option>
                            {territorialDirections.map((territorialDirection) => {
                              return (
                                  <option key={territorialDirection.id + '_td'} value={territorialDirection.id}>
                                    {territorialDirection.name}
                                  </option>
                              );
                            })}
                          </Select>}
                          {showSelectPOS && <Select {...pointsOfSale}
                                                    label="Точка продаж:"
                                                    name="pointOfSale"
                                                    Точка продаж
                                                    onChange={(e) => handleSelectPointOfSale(e.target.value)}
                          >
                            <option value={0}>Не выбрана</option>
                            {pointsOfSale.map((pointOfSale) => {
                              return (
                                  <option key={pointOfSale.id + '_td'} value={pointOfSale.id}>
                                    {pointOfSale.shortName}
                                  </option>
                              );
                            })}
                          </Select>}
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                  <br/>
                  <fieldset>
                    <Formik
                        initialValues={{
                        }}
                        onSubmit={async () => {}}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                            Скачивание документов
                          </div>
                          <button
                              className={"btn"}
                              type="button"
                              onClick={() => handleDealDocumentsGenerate()}
                              style={{ marginTop: "8px" }}>
                            Пакет документов сделки (без подписи) <Print className={"icon"} />
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{
                        }}
                        onSubmit={async () => {
                          alert("Устанавливаем сделку в статус Выдан");
                          await fetchSetDealIssued(creditContractId);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                            Проставить сделку в статус "Выдан" и  отправить документы в 1с
                          </div>
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginTop: "20px"}}
                          >
                            Запустить процедуру
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{
                        }}
                        onSubmit={async () => {
                          if (creditContractId) {
                            alert("Отправляем запрос на снятие авторизации");
                            await fetchRevokeAccount(creditContractId);
                          } else {
                            alert("Не выбрана сделка!")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                            Снять авторизацию по основному счету Клиента
                          </div>
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginTop: "20px"}}
                          >
                            Запустить процедуру
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{
                          applicationId: null
                        }}
                        onSubmit={async (values) => {
                          await fetchRejectCrifApplication(values.applicationId);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Отправить отказ в CRIF по id заявки
                          </div>
                          <div style={{ marginTop: "20px"}}/>

                          <TextInput
                              label="id Заявки : "
                              name="applicationId"
                              placeholder="123456"
                              style={{ width: "100px" }}
                          />

                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Отправить отказ
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                  <fieldset>
                    <Formik
                        initialValues={{
                          applicationId: null
                        }}
                        onSubmit={async (values) => {
                          await sendDocumentsToIbso(values.applicationId);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Отправить документы по заявке в ИБСО
                          </div>
                          <div style={{ marginTop: "20px"}}/>

                          <TextInput
                              label="id Заявки : "
                              name="applicationId"
                              placeholder="123456"
                              style={{ width: "100px" }}
                          />

                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Отправить документы
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                </div>}
                {page === "repayments" &&
                <div>
                  <fieldset>
                    <Formik
                        initialValues={{
                        }}
                        onSubmit={async () => {
                          await fetchPayOffDebts().then((data) => {
                            if (!data) {
                              alert("Успешно прокинули запрос на погашения");
                              fetchLastAllRepaymentDate().then((data) => setLastAllRepaymentDate(data));
                            } else {
                              alert("Ошибка: " + data);
                            }
                          })}}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Плановое погашение всех сделок
                          </div>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            {"Дата последнего запуска - " + lastAllRepaymentDate}
                          </div>
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginTop: "20px"}}
                          >
                            Запустить процедуру погашения для всех сделок
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{
                          sum: null,
                          date: null
                        }}
                        onSubmit={async (values) => {
                          if (creditContractId && values.sum !== null) {
                            alert("Отправили запрос на погашение по клиенту " + selectedClient);
                            await fetchPayment(creditContractId, values.sum, values.date);
                          } else {
                            alert("Не выбрана сделка или не указана сумма!!!")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Точечное погашение досрочное/плановое/просроченное по клиенту
                          </div>
                          <div style={{ marginTop: "20px"}}/>
                          <TextInput
                              type="date"
                              name="date"
                          />
                          <TextInput
                              label="Сумма: "
                              name="sum"
                              placeholder=""
                          />
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Отправить запрос в ИБСО
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{
                          sum: null,
                          date: null,
                          account: "40701810700820000033"
                        }}
                        onSubmit={async (values) => {
                          if (creditContractId && values.sum !== null) {
                            alert("Отправили погашение по клиенту " + selectedClient);
                            await fetchDirectReceipt(creditContractId, values.sum, values.date, values.account);
                          } else {
                            alert("Не выбрана сделка или не указана сумма!!!")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Погашение через р/с МКК
                          </div>
                          <div style={{ marginTop: "20px"}}/>

                          <TextInput
                              label="Сумма: "
                              name="sum"
                              placeholder=""
                          />
                          <TextInput
                              type="date"
                              name="date"
                          />
                          <Select
                              label="Р/с"
                              name="account"
                              Счет>
                            <option selected value="40701810700820000033">40701810700820000033</option>
                            <option value="40701810200770000241">40701810200770000241</option>
                          </Select>
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Записать погашение на р/с МКК
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {
                          let fileFormData = new FormData();
                          files.forEach((file) => {
                            if (file.id === "payments") {
                              const inputFile = document.getElementById(file.id);
                              fileFormData.append("file", inputFile.files[0]);
                            }
                          });
                          setLoading(true);
                          let resp = await fetchUploadManualPayments(fileFormData);
                          if (resp) {
                            alert("Успешно загрузили файл с погашениями")
                          }
                          setLoading(false);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>

                          <div style={{ fontWeight: "bold" }}>
                            Множественное точечное погашение из файла
                          </div>

                          <input
                              type="file"
                              id="payments"
                              onChange={uploadFile}
                              style={{ display: "none" }}
                          />

                          <button
                              className="btn"
                              type="button"
                              style={{ marginLeft: "10px"}}
                              onClick={() => {setPaymentsAttached(false);
                                document.getElementById("payments").click()}}>
                            Выберите файл <Upload className="icon" />
                          </button>

                          {paymentsAttached && !loading &&
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Загрузить данные в базу
                          </button>}
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                </div>}
                {page === "editingClientData" && <div>
                  {client && <Formik
                      initialValues={{
                        id: client.id,

                        firstName: client.firstName,
                        lastName: client.lastName,
                        middleName: client.middleName,
                        birthDate: client.birthDate,

                        passportSeries: client.passportSeries,
                        passportNumber: client.passportNumber,
                        passportIssueDate: client.passportIssueDate,
                        passportIssueAuthority: client.passportIssueAuthority,
                        passportIssueLocation: client.passportIssueLocation,

                        mobilePhone: client.mobilePhone,
                        email: client.email
                      }}
                      onSubmit={async (values) => {
                        await fetchUpdateClientData(values.id,
                            values.firstName,
                            values.lastName,
                            values.middleName,
                            values.birthDate,
                            values.passportSeries,
                            values.passportNumber,
                            values.passportIssueDate,
                            values.passportIssueAuthority,
                            values.passportIssueLocation,
                            values.mobilePhone,
                            values.email);}}
                  >
                    {() => (
                        <Form>
                          <fieldset>
                            <div className="grid-container">
                              <TextInput
                                  label="Серия"
                                  name="passportSeries"
                              />
                              <TextInput
                                  label="Фамилия"
                                  name="lastName"
                              />
                              <TextInput
                                  label="Номер"
                                  name="passportNumber"
                              />
                              <TextInput
                                  label="Имя"
                                  name="firstName"
                                  placeholder="Имя"
                              />
                              <TextInput
                                  label="Дата выдачи"
                                  name="passportIssueDate"
                                  placeholder="24/08/2021"
                              />
                              <TextInput
                                  label="Отчество"
                                  name="middleName"
                                  placeholder="Отчество"
                              />
                              <TextInput
                                  label="Выдавший орган"
                                  name="passportIssueAuthority"
                                  placeholder="Выдавший орган"
                              />
                              <TextInput
                                  label="Дата рождения"
                                  name="birthDate"
                                  placeholder="24/08/2021"
                              />
                              <TextInput
                                  label="Код подразделения"
                                  name="passportIssueLocation"
                                  placeholder="Код подразделения"
                              />
                              <TextInput
                                  label="Номер телефона"
                                  name="mobilePhone"
                                  placeholder="Номер телефона"
                              />
                              <TextInput
                                  label="Эл. почта"
                                  name="email"
                                  placeholder="email@example.com"
                              />
                              <button
                                  className="btn"
                                  type="submit"
                                  style={{ marginLeft: "20px"}}
                              >
                                Обновить данные
                              </button>
                            </div>
                          </fieldset>
                        </Form>
                    )}
                  </Formik>}
                </div>}
                {page === "references" && <div>
                  <fieldset>
                    <Formik
                        initialValues={{
                          quarter: "",
                          year: ""
                        }}
                        onSubmit={async (values) => {
                          let fileFormData = new FormData();
                          files.forEach((file) => {
                            if (file.id === "averageIncome") {
                              const inputFile = document.getElementById(file.id);
                              fileFormData.append("file", inputFile.files[0]);
                            }
                          });
                          fileFormData.append("quarter", values.quarter);
                          fileFormData.append("year", values.year);
                          let resp = await fetchUploadAverageIncome(fileFormData);
                          if (resp) {
                            alert("Успешно загрузили файл со среднедушевым доходом")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>

                          <div style={{ fontWeight: "bold" }}>
                            Данные по среднедушевому доходу
                          </div>

                          <TextInput
                              label="Квартал: "
                              name="quarter"
                              placeholder=""
                              style={{ width: "10px" }}
                          />
                          <TextInput
                              label="Год: "
                              name="year"
                              placeholder=""
                              style={{ width: "30px" }}
                          />

                          <input
                              type="file"
                              id="averageIncome"
                              onChange={uploadFile}
                              style={{ display: "none" }}
                          />

                          <button
                              className="btn"
                              type="button"
                              style={{ marginLeft: "10px"}}
                              onClick={() => {setAverageIncomeAttached(false);
                                              document.getElementById("averageIncome").click()}}>
                            Выберите файл <Upload className="icon" />
                          </button>

                          {averageIncomeAttached &&
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Загрузить данные в базу
                          </button>}
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                  <fieldset>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {
                          let fileFormData = new FormData();
                          files.forEach((file) => {
                            if (file.id === "fullCost") {
                              const inputFile = document.getElementById(file.id);
                              fileFormData.append("file", inputFile.files[0]);
                            }
                          });
                          let resp = await fetchUploadFullCost(fileFormData);
                          if (resp) {
                            alert("Успешно загрузили файл с пск")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>

                          <div style={{ fontWeight: "bold" }}>
                            Данные по среднерыночному значению пск
                          </div>

                          <input
                              type="file"
                              id="fullCost"
                              onChange={uploadFile}
                              style={{ display: "none" }}
                          />

                          <button
                              className="btn"
                              type="button"
                              style={{ marginLeft: "10px"}}
                              onClick={() => {setFullCostAttached(false);
                                              document.getElementById("fullCost").click()}}>
                            Выберите файл <Upload className="icon" />
                          </button>

                          {fullCostAttached &&
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Загрузить данные в базу
                          </button>}
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                  <fieldset>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {
                          let fileFormData = new FormData();
                          files.forEach((file) => {
                            if (file.id === "pointOfSale") {
                              const inputFile = document.getElementById(file.id);
                              fileFormData.append("file", inputFile.files[0]);
                            }
                          });
                          let resp = await fetchUploadPointOfSale(fileFormData);
                          if (resp) {
                            alert("Успешно загрузили файл с точками продаж")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>

                          <div style={{ fontWeight: "bold" }}>
                            Данные по точкам продаж
                          </div>

                          <input
                              type="file"
                              id="pointOfSale"
                              onChange={uploadFile}
                              style={{ display: "none" }}
                          />

                          <button
                              className="btn"
                              type="button"
                              style={{ marginLeft: "10px"}}
                              onClick={() => {setPointOfSaleAttached(false);
                                document.getElementById("pointOfSale").click()}}>
                            Выберите файл <Upload className="icon" />
                          </button>

                          {pointOfSaleAttached &&
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Загрузить данные в базу
                          </button>}
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {
                          let fileFormData = new FormData();
                          files.forEach((file) => {
                            if (file.id === "dealTypeMapping") {
                              const inputFile = document.getElementById(file.id);
                              fileFormData.append("file", inputFile.files[0]);
                            }
                          });
                          let resp = await fetchUploadPskMapping(fileFormData);
                          if (resp) {
                            alert("Успешно загрузили файл меппинга пск")
                          }
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>

                          <div style={{ fontWeight: "bold" }}>
                            Меппинг ПСК
                          </div>

                          <input
                              type="file"
                              id="dealTypeMapping"
                              onChange={uploadFile}
                              style={{ display: "none" }}
                          />

                          <button
                              className="btn"
                              type="button"
                              style={{ marginLeft: "10px"}}
                              onClick={() => {setDealTypeMappingAttached(false);
                                document.getElementById("dealTypeMapping").click()}}>
                            Выберите файл <Upload className="icon" />
                          </button>

                          {dealTypeMappingAttached &&
                              <button
                                  className="btn"
                                  type="submit"
                                  style={{ marginLeft: "10px"}}
                              >
                                Загрузить данные в базу
                              </button>}
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                </div>}
                {page === "addUser" && <div>
                  <fieldset>
                    <Formik
                        initialValues={{
                          login: "",
                          password: "",
                          comment: ""
                        }}
                        onSubmit={async (values) => {
                          await fetchCreateNewUserManager(values.login, values.password, values.comment);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Добавить пользователя с ролью "MANAGER"
                          </div>
                          <div style={{ marginTop: "20px"}}/>
                          <TextInput
                              label="Логин: "
                              name="login"
                              placeholder=""
                          />

                          <TextInput
                              label="Пароль: "
                              name="password"
                              placeholder=""
                          />
                          <div/>
                          <TextInput
                              label="Комментарий: "
                              name="comment"
                              placeholder=""
                          />

                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Записать пользователя
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                </div>}
                {page === "other" && <div>
                  <fieldset>
                    <Formik
                        initialValues={{
                          applicationId: null
                        }}
                        onSubmit={async (values) => {
                          await handleDealRegistryPackGenerate(values.applicationId);
                        }}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Скачивание архива реестров, направленных в ИБСО по сделке
                          </div>
                          <div style={{ marginTop: "20px"}}/>

                          <button
                              className="btn"
                              type="submit"
                              style={{ marginLeft: "10px"}}
                          >
                            Скачать архив с реестрами
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Проверка превышения допустимого значения ПДН 80
                          </div>
                          <Switch
                              onChange={async () => {
                                setRequestsLocked(true);
                                await changeCurrentMpl80State()
                                    .then((data) => {
                                      setTimeout(() => {
                                        setRequestsLocked(false);
                                      }, 2000);
                                      setCurrentMpl80State(() => data);
                                    });
                              }}
                              checked={currentMpl80State}
                              disabled={requestsLocked}
                              color={"primary"}
                          />
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Проверка превышения допустимого значения ПДН 50-80
                          </div>
                          <Switch
                              onChange={async () => {
                                setRequestsLocked(true);
                                await changeCurrentMpl5080State()
                                    .then((data) => {
                                      setTimeout(() => {
                                        setRequestsLocked(false);
                                      }, 2000);
                                      setCurrentMpl5080State(() => data);
                                    });
                              }}
                              checked={currentMpl5080State}
                              disabled={requestsLocked}
                              name={"mpl5080"}
                              color={"primary"}
                          />
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Автоматическая отправка платежей
                          </div>
                          <Switch
                              onChange={async () => {
                                setRequestsLocked(true);
                                await changeCurrentPaymentState()
                                    .then((data) => {
                                      setTimeout(() => {
                                        setRequestsLocked(false);
                                      }, 2000);
                                      setCurrentPaymentState(() => data);
                                    });
                              }}
                              checked={currentPaymentState}
                              disabled={requestsLocked}
                              name={"paymentState"}
                              color={"primary"}
                          />
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Текущее состояние фильтров заявок по ставкам
                            <br/>
                            (Включено - значит не принимаем заявку с такой ставкой)
                          </div>
                          <div>
                            {interestFilterList.map((item, index) => (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center' }}>
                                  <p key={index}>{item.description}</p>
                                  <Switch
                                      onChange={async () => {
                                        setRequestsLocked(true);
                                        await changeCurrentRateFilterState(item.id).then((data) => {
                                          setTimeout(() => {
                                            setRequestsLocked(false);
                                          }, 2000);
                                          if (data != null) {
                                            setInterestFilterList(prevList =>
                                                prevList.map(filterItem =>
                                                    filterItem.id === item.id ? {...filterItem, enabled: data.enabled} : filterItem
                                                )
                                            );
                                          }
                                        });
                                      }}
                                      checked={item.enabled}
                                      disabled={requestsLocked}
                                      color={"primary"}
                                  />
                                </div>
                            ))}
                          </div>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>

                  <fieldset>
                    <Formik
                        initialValues={{
                          applicationId: null,
                          tosave: false,
                          sendToMyMfo: false
                        }}
                        onSubmit={async (values) => {
                          await generateDocumentRequest(values.applicationId,
                              values.genDate, values.tosave,
                              values.sendToMyMfo, docType )}}>
                      {() => <Form>
                        <div style={{ display: "table" }}>
                          <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                            Генерация документов по id заявки
                          </div>
                          <br/>
                          <TextInput
                              label="id Заявки : "
                              name="applicationId"
                              placeholder="123456"
                              style={{ width: "100px" }}
                          />
                          <br/>
                          <TextInput
                              label="Дата генерации : "
                              type="date"
                              name="genDate"
                              style={{width: "100px"}}
                          />
                          <br/>
                          <div>
                            <Select {...docTypes}
                                    label="Тип документа"
                                    name="docType"
                                    Тип документа
                                    onChange={(e) => setDocType(e.target.value)}
                            >
                              <option value={0}>Не выбран</option>
                              {docTypes.map((doc) => {
                                return (
                                    <option key={doc.id + '_doc'} value={doc.id}>
                                      {doc.name}
                                    </option>
                                );
                              })}
                            </Select>
                          </div>
                          <br/>
                          <div>
                            <label>
                              <Field type="checkbox" name="tosave" />
                              Генерация с сохранением и обновлением пакета
                            </label>
                          </div>
                          <br/>
                          <div>
                            <label>
                              <Field type="checkbox" name="sendToMyMfo" />
                              Отправить полученный пакет документов в 1с
                            </label>
                          </div>
                          <button
                              className="btn"
                              type="submit"
                              style={{ marginTop: "20px" }}
                          >
                            Сгенерировать
                          </button>
                        </div>
                      </Form>}
                    </Formik>
                  </fieldset>
                </div>}
              </div>
            </td>
            <td style={{ width: "460px" }}>
              <fieldset>
                <Formik
                    initialValues={{
                      lastname: null,
                      firstname: null,
                      middlename: null,
                      series: null,
                      number: null,
                    }}
                 onSubmit={{}}>
                  {({values}) => <Form>
                    <div>
                      <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                        {!clientId && "Клиент не выбран!"}
                        {clientId && "Выбранный клиент - " + selectedClient + ", сделка " + creditContractId}
                      </div>
                      <ul style={{ marginLeft: "-40px", marginTop: "15px" }}>
                        <li>
                          <TextInput
                              name="lastname"
                              placeholder="Фамилия"

                          />
                          <TextInput
                              name="series"
                              placeholder="Серия"
                          />
                        </li>
                        <li>
                          <TextInput
                              name="firstname"
                              placeholder="Имя"
                          />
                          <TextInput
                              name="number"
                              placeholder="Номер"
                          />
                        </li>
                        <li>
                          <TextInput
                              name="middlename"
                              placeholder="Отчество"
                          />
                          <button
                              className="btn"
                              type="text"
                              style={{ marginLeft: "20px"}}
                              onClick={() => fetchClients(values).then((data) => setClients(data))}
                          >
                            Найти
                          </button>
                        </li>

                      </ul>
                      <div>
                        <Select {...clients}
                                label="Клиент"
                                name="client"
                                Клиент
                                onChange={(e) => handleSelectClient(e.target.value)}
                        >
                          <option value={0}>Не выбран</option>
                          {clients.map((client) => {
                            return (
                                <option key={client.id + '_client'} value={client.id}>
                                  {client.fullName}
                                </option>
                            );
                          })}
                        </Select>
                      </div>
                      <div>
                        <Select {...deals}
                                label="Сделка"
                                name="deal"
                                Сделка
                                onChange={(e) => setCreditContractId(e.target.value)}
                        >
                          <option value={0}>Не выбрана</option>
                          {deals.map((deal) => {
                            return (
                                <option key={deal.id} value={deal.id}>
                                  {deal.number}
                                </option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </Form>}
                </Formik>
              </fieldset>
            </td>
          </tr>
        </table>
      </section>
    </div>
  );
}
