import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import Header from "../components/UI/Header";
import {TextInput} from "../components/UI/FormItems";
import Rejection from "./Rejection";
import {applicationOpened, validateClientData} from "../api";
import EmptyContent from "../components/EmptyContent";


const ClientPassportDataVerification = ({ application, onCallBack }) => {

    const [showRejection, setShowRejection] = useState(false);
    const [activeBtn, setActiveBtn] = useState(true);
    const [validationError, setValidationError] = useState(false);
    const [firstTry, setFirstTry] = useState(true);

    const initialValues = {
        client: {
            lastName: "",
            firstName: "",
            middleName: "",
            birthDate: "",
            passportSeries: "",
            passportNumber: "",
            passportIssueDate: ""
        }
    };

    useEffect(() => {
        async function initialize() {
            await applicationOpened(application.id);
        }

        initialize().then(() => {});
    }, [application]);

    const clientSchema = Yup.object({
        client: Yup.object({
            lastName: Yup.string().required(),
            firstName: Yup.string().required(),
            middleName: Yup.string().required(),
            birthDate: Yup.string().required(),
            passportSeries: Yup.string().required().min(4).max(4),
            passportNumber: Yup.string().required().min(6).max(6),
            passportIssueDate: Yup.string().required()
        })
    });

    if (validationError) {
        return <EmptyContent header={"Обработка заявки"} message={"Дальнейшее оформление невозможно. Ошибка при верификации, необходимо направить информацию на почтовый ящик МКК."}/>;
    }

    return (
        <div className="client-verification">
            <Header title={"Проверка данных клиента (вводятся строго в соответсвии с паспортом)"} />
            <Formik
                validationSchema={clientSchema}
                validateOnMount
                initialTouched={{
                    client: {
                        lastName: true,
                        firstName: true,
                        middleName: true,
                        birthDate: true,
                        passportSeries: true,
                        passportNumber: true,
                        passportIssueDate: true
                    }
                }}
                initialValues={initialValues}
                onSubmit={async (values, actions) => {
                    if (!activeBtn) return ;
                    setActiveBtn(false);
                    validateClientData(values, application.id).then((resp) => {
                        if (resp) {
                            onCallBack();
                        } else {
                            if (firstTry) {
                                alert("Введите данные клиента повторно. Допущена ошибка в ранее введенных данных.");
                                actions.resetForm();
                                setActiveBtn(true);
                                setFirstTry(false)
                            } else {
                                setValidationError(true);
                            }
                        }
                    })
                }}>
                {({values}) => (
                    <Form>
                        <fieldset>
                            <div>
                                <table>
                                    <tr>
                                        <td style = {{border: 0}}>
                                            <ul className="ul-column">
                                                <TextInput
                                                    name="client.lastName"
                                                    placeholder="Фамилия"
                                                    value={values.client.lastName}
                                                />
                                                <TextInput
                                                    name="client.firstName"
                                                    placeholder="Имя"
                                                    value={values.client.firstName}
                                                />
                                                <TextInput
                                                    name="client.middleName"
                                                    placeholder="Отчество"
                                                    value={values.client.middleName}
                                                />
                                                <label style = {{padding: 0, fontSize: 12, fontWeight: "bold"}}>Дата рождения клиента</label>
                                                <TextInput
                                                    type="date"
                                                    name="client.birthDate"
                                                    value={values.client.birthDate}
                                                />
                                            </ul>
                                        </td>
                                        <td style = {{border: 0}}>
                                            <ul className="ul-column">
                                                <TextInput
                                                    name="client.passportSeries"
                                                    placeholder="Паспорт серия"
                                                    value={values.client.passportSeries}
                                                />
                                                <TextInput
                                                    name="client.passportNumber"
                                                    placeholder="Паспорт номер"
                                                    value={values.client.passportNumber}
                                                />
                                                <label style = {{padding: 0, fontSize: 12, fontWeight: "bold"}}>Дата выдачи паспорта</label>
                                                <TextInput
                                                    type="date"
                                                    name="client.passportIssueDate"
                                                    value={values.client.passportIssueDate}
                                                />
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div style={{marginLeft: "20%"}}>
                                <button
                                    className={activeBtn ? "btn" : "not-active-btn"}
                                    type="text"
                                    onClick={() => setShowRejection(true)}
                                    value="decline">
                                    Отказ
                                </button>
                                <button
                                    className={activeBtn ? "btn" : "not-active-btn"}
                                    type="submit"
                                    style={{ marginLeft: "20px"}}
                                >
                                    Перейти к обработке заявки
                                </button>
                            </div>
                        </fieldset>
                    </Form>
                )}
            </Formik>

            {activeBtn && showRejection && <Rejection
                applicationId={application.id}
                onCallBack={() => setShowRejection(false)}/>}

        </div>
    );
};

export default ClientPassportDataVerification;
